import {createApp} from 'vue'
import './style.css'
import App from './App.vue'
import router from "./router.ts";
import {i18n} from "./localization/localization.ts";
import adapter from 'webrtc-adapter';
import * as Sentry from '@sentry/vue';

console.log(`Browser: ${adapter.browserDetails.browser}:${adapter.browserDetails.version}`)

const app = createApp(App)

Sentry.init({
    app,
    dsn: 'https://27249745a0b644628ebe9d997510d373@app.glitchtip.com/8557',
    integrations: [Sentry.browserTracingIntegration({router}), Sentry.replayIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        "localhost",
        // "https://yourteams.org",
        // "https://stage.yourteams.org",
        // "https://b1.yourteams.org",
        // "https://b2.yourteams.org",
        // "https://b3.yourteams.org"
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

app.use(router)
app.use(i18n)
app.mount('#app')

